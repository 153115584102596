import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from "../helpers"

export default class extends Controller {
  connect() {
    this.init();
  }

  init() {
    for(var el of document.querySelectorAll('.css-table-cell input[type="checkbox"]:not(.show-inactive-check)')) {
      el.parentNode.addEventListener("mouseover", this.highlight, false);
      el.parentNode.addEventListener("mouseout", this.unhighlight, false);
    }
  }

  highlight(event) {
    var el = event.target.type == 'checkbox' ? event.target : event.target.querySelector('input');
    var rCell = el.parentNode.parentNode.querySelectorAll('.css-table-cell')[0];
    var cCells = document.querySelectorAll(`#user-projects-table .css-table-header .css-table-cell[data-project_id="${el.dataset.project_id}"]`);

    helpers.addClass(rCell, 'highlighted');
    for(var cell of cCells) {
      helpers.addClass(cell, 'highlighted');
    }
  }

  unhighlight(el) {
    var el = event.target.type == 'checkbox' ? event.target : event.target.querySelector('input');
    var rCell = el.parentNode.parentNode.querySelectorAll('.css-table-cell')[0];
    var cCells = document.querySelectorAll(`#user-projects-table .css-table-header .css-table-cell[data-project_id="${el.dataset.project_id}"]`);
    
    helpers.removeClass(rCell, 'highlighted');
    for(var cell of cCells) {
      helpers.removeClass(cell, 'highlighted');
    }
  }

  update(event) {
    const data = event.currentTarget.dataset;
    const checked = event.currentTarget.checked;

    if(data.id && !checked) {
      var url = this.element.dataset.destroy_url.replace('$id$', data.id);
      general.deleteJson(url, "", function(result) {
        delete data.id;
        // general.successSnack("Uspešno izbrisan!");
      });
    } else if(!data.id && checked) {
      general.postJson(this.element.dataset.create_url, {
          user_project: {
            user_id: data.user_id, 
            project_id: data.project_id 
          }
        }, function(result) {
          data.id = result.id;
          // general.successSnack("Uspešno dodan!");
        });
    }
  }

  organisationChanged(event) {
    this.refreshTable();
  }

  url() {
    return document.querySelector('#user_projects').dataset.url;
  }

  refreshTable() {
    var self = this;
    var organisationId = document.querySelector('#organisation-picker') && document.querySelector('#organisation-picker').value;
    var showInactive = document.querySelector('#show_inactive').checked;
    var showEmployed = document.querySelector('#show_employed').checked;
    var showStudent = document.querySelector('#show_student').checked;
    var showOther = document.querySelector('#show_other').checked;

    var data = {
      organisationId,
      showInactive,
      showEmployed,
      showStudent,
      showOther
    };

    var query = new URLSearchParams(data).toString();

    var success = function(result) {
      helpers.setHtml(document.querySelector('#table-holder'), result.html);
      self.init();
    };

    general.getJson(`${this.url()}?${query}`, success);
  }
}
