import Bs5Utils from 'bs5-utils';

var queue = [];

export function fetchJson(url, method, data, successCallback, errorCallback, formData) {
	let headers = {
      'Accept': 'application/json, text/plain, */*',
	};
	if(!formData) {
		headers['Content-Type'] = 'application/json';
	}

	fetch(url, {
		method,
		headers,
    body: formData || !data ? data : JSON.stringify(data)
	}).then(async function(response) {
		let result = await response.json();
		if(response.ok) {
			if(successCallback) {
				successCallback(result);
			}
		} else {
			errorSnack(result.error || "Napaka");
			if(errorCallback) {
				errorCallback(result);
			}
		}
		if(method != 'GET') {
			queue.shift();
			executeNext();
		}
	});
}

function addToQueue(url, method, data, successCallback, errorCallback, formData) {
	queue.push([url, method, data, successCallback, errorCallback, formData]);
	if(queue.length == 1) {
		executeNext();
	}
}

function executeNext() {
	if(queue.length == 0) {
		return;
	}

	var params = queue[0];
	fetchJson(...params);
}

export function getJson(url, successCallback, errorCallback) {
	fetchJson(url, 'GET', null, successCallback, errorCallback);
}

export function postJson(url, data, successCallback, errorCallback, formData) {
	addToQueue(url, 'POST', data, successCallback, errorCallback, formData);
}

export function putJson(url, data, successCallback, errorCallback, formData) {
	addToQueue(url, 'PUT', data, successCallback, errorCallback, formData);
}

export function deleteJson(url, data, successCallback, errorCallback) {
	addToQueue(url, 'DELETE', data, successCallback, errorCallback);
}

export function successSnack(title) {
	const bs = new Bs5Utils();
	return bs.Snack.show('success', title, 800);
}

export function errorSnack(title) {
	const bs = new Bs5Utils();
	return bs.Snack.show('danger', title, 2000);
}

export function modalWithoutButtons(title, content) {
	const bs = new Bs5Utils();
	return bs.Modal.show({
	    type: 'primary',
	    title: title,
	    content: content
	});
}

export function modal(title, content, saveCallback) {
	const bs = new Bs5Utils();
	return bs.Modal.show({
	    type: 'primary',
	    title: title,
	    content: content,
	    backdrop: 'static',
	    buttons: [{
	    	text: 'Prekliči',
	    	class: 'btn btn-sm btn-secondary',
	    	type: 'dismiss'
	    }, {
	    	text: 'Shrani',
	    	class: 'btn btn-sm btn-primary',
	    	handler: saveCallback
	    }]
	});
}

export function formToObject( elem ) {
  let output = {};
  new FormData( elem ).forEach(
    ( value, key ) => {
      // Check if property already exist
      if ( Object.prototype.hasOwnProperty.call( output, key ) ) {
        let current = output[ key ];
        if ( !Array.isArray( current ) ) {
          // If it's not an array, convert it to an array.
          current = output[ key ] = [ current ];
        }
        current.push( value ); // Add the new value to the array.
      } else {
        output[ key ] = value;
      }
    }
  );
  return output;
}

export function randomString() {
	return "x"+Math.random().toString(36).slice(2);
}