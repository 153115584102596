// Configure your import map in config/importmap.rb. Read more: https://github.com/rails/importmap-rails
// import "@hotwired/turbo-rails"
import "./controllers"
import * as general from './general'
import * as helpers from './helpers'
// import * as fa from "@fortawesome/fontawesome-free"
import Bs5Utils from 'bs5-utils'

window.Twine = require('twine');
window.bootstrap = require("bootstrap/dist/js/bootstrap");

window.numeral = require("numeral");
require('numeral/locales/sl');
numeral.locale('sl');

window.I18n = require('i18n-js');
I18n.defaultLocale = numeral.locale();
I18n.locale = numeral.locale();