import { Controller } from "@hotwired/stimulus"
import * as general from "../general"
import * as helpers from '../helpers'

export default class extends Controller {

  connect() {
    this.init();
  }

  init() {

  }

  url() {
    return document.querySelector('#vacation_entries').dataset.url;
  }

  prev(event) {
    this.goTo(event, 'previous');
  }

  next(event) {
    this.goTo(event, 'next');
  }

  goTo(event, direction) {
    var parent = event.currentTarget.parentNode;
    var el = parent[`${direction}ElementSibling`];

    helpers.addClass(parent, 'hide');
    helpers.removeClass(el, 'hide');
    helpers.addClass(el, 'active');
    helpers.removeClass(parent, 'active');

    this.refreshTable();
  }

  organisationChanged(event) {
    this.refreshTable();
  }

  refreshTable() {
    var self = this;
    var monthData = JSON.parse(document.querySelector('.month.active').dataset.obj);
    var organisationId = document.querySelector('#organisation-picker').value;
    var data = {
      month: monthData.month,
      year: monthData.year,
      organisationId
    };

    var query = new URLSearchParams(data).toString();

    var success = function(result) {
      helpers.setHtml(document.querySelector('#table-holder'), result.html);
      self.init();
    };

    general.getJson(`${this.url()}?${query}`, success);
  }
}