import * as general from './general';

export function removeAll(selector) {
	for(let el of document.querySelectorAll(selector)) {
      el.remove();
    }
}

export function removeClassFromAll(selector, klass) {
	for(let el of document.querySelectorAll(selector)) {
      removeClass(el, klass);
    }
}

export function hasClass(el, klass) {
	return !!el.className.match(new RegExp('(\\s|^)'+klass+'(\\s|$)'));
}

export function addClass(el, klass) {
	if (!hasClass(el, klass)) {
		el.className += ` ${klass}`;
	}
}

export function removeClass(el, klass) {
	if (hasClass(el,klass)) {
		var reg = new RegExp('(\\s|^)'+klass+'(\\s|$)');
		el.className=el.className.replace(reg,' ');
	}
}

export function setHtml(el, html) {
	if(el) {
		el.innerHTML = html;
	}
}

export function insertAfter(el, newEl) {
	el.parentNode.insertBefore(newEl, el.nextSibling);
}

export function addErrorDivAfter(el, text) {
  let div = document.createElement('div');
  addClass(div, 'invalid-feedback');
  setHtml(div, text);
  insertAfter(el, div);
}

export function setAttribute(el, attr, val) {
	el.setAttribute(attr, val);
}

export function removeAttribute(el, attr) {
	el.removeAttribute(attr);
}

export function isValueEmpty(val) {
	return !val;
}

export function isValueChanged(old, current) {
	return ( isValueEmpty(old) && !isValueEmpty(current) ) || ( !isValueEmpty(old) && isValueEmpty(current) ) || ( !isValueEmpty(old) && !isValueEmpty(current) && old != current );
}

// val in format "xx.yy"
export function toNumber(val) {
	return I18n.toNumber((val || 0.0).toString(), {strip_insignificant_zeros: true, separator: numeral.localeData().delimiters.decimal, delimiter: numeral.localeData().delimiters.thousands })
}

export function validateNumber(val) {
	return !val || /^\d{1,3}(\,\d{1,2})?$/.test(val.trim());
}

export function getValue(type, el, old) {
  if(type == 'number') {
    if(validateNumber(el.value)) {
      return numeral(el.value).value();
    } else {
      general.errorSnack(`Vpisana vrednost (${el.value}) ni v ustreznem formatu! Uporabiti je potrebno decimalno vejico!`);
      return old;
    }
  } else if(type == 'boolean') {
    return el.checked;
  } else {
    return el.value;
  }
}